@import "_colors.scss";

html, body {
    margin: 0;
    height: 100%;
}

// https://stackoverflow.com/a/34190674/3014500
body {
    overflow-x: hidden;
}

app-root,
.fullscreen {
    display: block;
    width: 100%;
    height: 100%;
}

app-layout {
    display: block;
    width: 100%;
    height: 100%;
}

* {
    outline: none;
}

.page-container {
    max-width: 1280px;
    padding: 20px;
}

a {
    text-decoration: none;
}

.cards-custom {
    color: #ffffff;
    font-size: 13px;
}

.cards-custom .mat-input-placeholder {
    color: #ffffff !important;
    font-size: 12px;
}

.cards-custom .mat-focused {
    color: #ffffff !important;
}

.cards-custom .mat-input-underline {
    border-color: #ffffff;
}

.cards-custom .mat-input-underline .mat-input-ripple {
    background-color: #ffffff;
}

.cards-custom .mat-checkbox-label {
    font-size: 12px;
}

.cards-custom .mat-checkbox-background {
    background: #ffffff !important;
}

.cards-custom .mat-checkbox-checkmark-path {
    stroke: #03a9f4 !important;
}

.cards-customTwo {
    color: #ffffff;
    font-size: 13px;
    width: 100%;
}

.cards-customTwo .mat-input-placeholder {
    color: #ffffff !important;
    font-size: 10px;
}

.cards-customTwo .mat-focused {
    color: #ffffff !important;
}

.cards-customTwo .mat-input-underline {
    border-color: #ffffff;
}

.cards-customTwo .mat-input-underline .mat-input-ripple {
    background-color: #ffffff;
}

.cards-customTwo .mat-checkbox-label {
    font-size: 12px;
}

.cards-customTwo .mat-checkbox-background {
    background: #ffffff !important;
}

.cards-customTwo .mat-checkbox-checkmark-path {
    stroke: #03a9f4 !important;
}

.cardWritten_card_header .mat-card-header-text {
    align-items: center;
    display: flex;
}

.cardWritten_card_header .mat-card-title {
    margin-bottom: 0px;
}

.pagesCustom {
    background: $colorWhite;
    margin-bottom: 0px !important;

    ::ng-deep .navDefault_container_button {
        color: $colorBlack;
    }

    // ::ng-deep .toolbar_menuContent_brandLogo
    //     background: url(/assets/images/logo-color.png)no-repeat
    //     background-size: contain
} //     background-position: center

.cdk-global-scrollblock {
    top: 0px !important;
}
